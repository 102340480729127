import { names } from "./datas";
import moment from 'moment-timezone';

export const convertMoney = (money) => {
  return parseFloat(money?.toFixed(0) ?? 0).toLocaleString("en");
};
export const generateRandomName = () => {
  const randomIndex = Math.floor(Math.random() * names.length);
  return names[randomIndex];
};

export const generateRandomNumber = () => {
  const min = 10000;
  const max = 1000000;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const convertTimeYYMMDDV2 = (dateString) => {
  return moment(dateString).locale("vi").tz("Asia/Seoul").format("YY.MM.DD LTS");
};

export const convertTimeYYMMDDV3 = (dateString) => {
  const timeStamp = Date.parse(dateString);
  return moment(timeStamp + 32400000).locale("vi").tz("Asia/Seoul").format("YY.MM.DD LTS");
};

export const convertTimeYYMMDD = (timestamp) => {
  return `${moment(timestamp * 1000).tz("Asia/Seoul").format("YY.MM.DD LTS")}`;
};

export const formatDateV2 = (timestamp) => {
  return `${moment(timestamp).tz("Asia/Seoul").format("YY.MM.DD")}`;
};

export const formatTimeV2 = (timestamp) => {
  return `${moment(timestamp).tz("Asia/Seoul").format("HH:mm")}`;
};

export const formatDateV1 = (dateString) => {
  return moment(dateString).tz("Asia/Seoul").format("YY.MM.DD");
};

export const formatTime = (dateString) => {
  return moment(dateString * 1000).tz("Asia/Seoul").format("HH:mm");
};

export const formatDate = (dateString) => {
  return moment(dateString * 1000).tz("Asia/Seoul").format("YY.MM.DD");
};

export const formatTimeKr = (time) => {
  const timeStamp = Date.parse(time);
  let timeKr = formatTime(timeStamp / 1000 + 32400);
  return timeKr;
};

export const formatDateKr = (time) => {
  const timeStamp = Date.parse(time);
  let timeKr = formatDate(timeStamp / 1000 + 32400);
  return timeKr;
};

export const converTime = (timestamp, type) => {
  const date = new Date(timestamp * 1000);
  // const formattedDate = date.toLocaleDateString();
  const formattedTime = date.toLocaleTimeString();
  if (type) {
    return `${moment(timestamp * 1000).tz("Asia/Seoul").format("YY.MM.DD LTS")}`;
  } else {
    return `${moment(timestamp * 1000).tz("Asia/Seoul").format(
      "YY.MM.DD LTS"
    )} ${formattedTime}`;
  }
};

export const koreaTime = (originTime) => {
  const originalDate = new Date(originTime.replace(' ', 'T') + 'Z');
  const millisecondsToAdd = 9 * 60 * 60 * 1000;
  const newDate = new Date(originalDate.getTime() + millisecondsToAdd);
  const newDateStr = newDate.toISOString().replace('T', ' ').slice(0, 19);
  return newDateStr;
}

export const convertKrTime = (time) => {
  const timeStamp = Date.parse(time);
  let timeKr = convertTimeYYMMDD(timeStamp / 1000 + 32400);
  return timeKr;
};

export const setCommaFormat = (n2) => {
  const cn2 = n2.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  return cn2;
};
